.planesContainer {
  display: flex;
  flex-wrap: wrap;

  .plan {
    width: 265px;
    margin-right: 16px;
    margin-bottom: 12px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }

  .available {
    background-color: white;
  }

  .selected {
    background-color: #3DB14A;
    color: white;
  }

  .contentWrapper {
    padding: 16px;
    margin-bottom: 16px;
  }

  .line {
    height: 1px;
    background-color: #bbbbbb;
  }

  h3 {
    font-size: 20px;
  }

  .purchase {
    font-size: 16px;
  }

  .price, .oldPrice {
    font-weight: 600;
  }

  .buyBtn {
    display: block;
    width: 100%;
    padding: 12px;
    text-align: center;
    color: white;
    border: none;
    background: linear-gradient(86.49deg, #73C94B 5.78%, #15B568 94.22%);
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
  }

  .activeLabel {
    padding: 8px;
  }
}

.features {
  font-size: 16px;
  color: #333;

  li {
    margin: 4px 0;
  }
}
