@import "~styles/variables";

.tabsContainer {
  background: $white;
  border: 1px solid $grey;
  border-radius: 8px;
  padding: 4px;
}

.tabsContainerSmall {
  padding: 0;
}

.tab {
  min-height: 40px;
  width: 196px;
  font-weight: 600;
  cursor: pointer;

  @media screen and (max-width: $mobile-up) {
    min-width: 50%;
    width: 100%;
  }
}

.tabActive {
  background: $green-gradient;
  border-radius: 4px;
  color: $white;
  font-weight: 600;
}

.tabSmall {
  width: 88px;
}

.tabMedium {
  width: 172px;
  min-height: 32px;
}
