.noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background: rgba(255,255,255,0.8);
  transition: all .3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0,0,0,0.5);
}

.multiSelectValue {
  padding: 3px 6px;
  background-color: #1D65A7;
  border-radius: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.wrapperSelectedCount {
  position: relative;
  width: 48px;

  .selectedCount {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 42px;
    transform: translate(-50%, 0);
    text-align: center;
  }
}

.datePickerDropdownWrapper {
  display: flex;
  flex-direction: column;
  width: 120px;
  justify-content: space-between;
  margin-left: 10px;

  .apply {
    font-size: 14px;
    font-weight: 600;
    color: #3DB14A;
    background: rgba(61, 177, 74, 0.1);
    border: none;
    padding: 8px;
    border-radius: 2px;
  }
}

.calendarContainer {
  padding: 10px;
  display: flex !important;
}
