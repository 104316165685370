@import "~styles/variables";

.buttonStatus {
  max-width: 132px;
  margin-right: 12px;
}

.buttonCsv {
  max-width: 140px;
  margin-right: 12px;

  @media (max-width: 576px) {
    max-width: 110px;
  }
}

.dotStatus {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: $red;
}

.dotStatusActive {
  background-color: $green;
}

.buttonEdit {
  max-width: 88px;
}

.arrowLeft {
  margin-right: 27px;
}

.sharedLabel {
  padding: 3px 6px;
  background-color: #1d65a7;
  border-radius: 6px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.warning_text {
  color: $red;
  margin-left: 87px;

  @media screen and (max-width: 991px) {
    margin-left: 0;
    text-align: center;
  }
}
