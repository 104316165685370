@import "~styles/variables";

.userNotificationsContainer {
  z-index: 10;
  width: 432px;
  position: absolute;
  right: 0;
  top: 64px;

  background: $white;

  box-shadow: 0px 0px 50px rgba(53, 51, 51, 0.15);
  border-radius: 4px;

  @media screen and (max-width: $mobile-down) {
    position: fixed;
    min-width: auto;
    width: auto;
    bottom: 0;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    border-radius: 0;
  }
}

.userNotificationsHeader {
  border-bottom: 1px solid $grey;
}

.userNotificationsWrapper {
  max-height: 258px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media screen and (max-width: $mobile-down) {
    max-height: none;
    flex-shrink: 1;
  }
}

.userNotificationsWrapper::-webkit-scrollbar {
  display: none;
}

.userNotificationGreyBG {
  background-color: rgba($grey, .3);
}

.userNotificationsUnreadDot {
  background-color: $green;
  min-width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: 5px;
}
