.wrapperWidgets {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .widget {
    position: relative;
    padding: 16px;
    margin: 10px 0;
    min-width: 150px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 2px solid #ECECEC;
    border-radius: 12px;
    --w: calc(50% - 30px);
    --c: black;
    --b: 2px;
    --r: 12px;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &.green {
      --c: #3DB14A;
    }

    &.blue {
      --c: #3396FF;
    }

    &.yellow {
      --c: #F5B000;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: -2px;
      left: -2px;
      background: var(--c);
      padding: var(--b);
      border-radius: var(--r);
      -webkit-mask:
              linear-gradient(0deg,#000 calc(2*var(--b)),#0000 0) 50% var(--b)/calc(100% - 1.5*var(--w)) 100% repeat-y,
              linear-gradient(-90deg,#000 calc(2*var(--b)),#0000 0) var(--b) 50%/100% calc(100% - 3*var(--w)) repeat-x,
              linear-gradient(#000 0 0) content-box,
              linear-gradient(#000 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }
  }

  .name {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-right: 15px;
  }

  .value {
    font-size: 26px;
    font-weight: 600;
    color: var(--c);
  }
}
