@import "~styles/variables";

.container {
  background: $orange-light;
  color: $white;
  padding: 15px 20px;
  margin-left: 272px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  border-bottom: 1px solid $white;

  @media screen and (max-width: $hiddenMenu) {
    margin: 0;
  }
}
