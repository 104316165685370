.input {
  position: absolute;
  border: none;
  font-size: 32px;
  text-align: center;
  background-color: transparent;
  outline: none;
  caret-color: #fff;

  width: 32px;
  top: 0;
  bottom: 0
}

.display {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  position: relative;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}

.shadows {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 0 0 0 4px rgba(58, 151, 212, 0.28);
}
