@import "~styles/variables";

* {
  box-sizing: border-box;
}

body, html {
  height: 100%;
}

body {
  margin: 0;
  background: $light-grey;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

a {
  text-decoration: none;
  outline: none;
  color: #45bc5d;
  -webkit-tap-highlight-color: transparent;
}

a:hover {
  text-decoration: underline;;
}

button, input, select, textarea {
  outline: none;
}

// For prevent whitespace under these elements
img, svg {
  vertical-align: top;
}

.align-center {
  display: block;
  margin: auto;
}

.row {
  display: flex;
  justify-content: space-between;
}

.green {
  color: #3DB14A;
}

.page-content {
  width: calc(100% - #{$sidebar-width});
  margin-left: $sidebar-width;

  @media screen and (max-width: $hiddenMenu) {
    width: 100%;
    margin-left: 0;
  }
}

.page-content-simple {
  min-height: calc(100% - 120px);
}

.page-content-manage-budgets {
  min-height: calc(100% - 158px);
}

.text-capitalize {
  text-transform: capitalize;
}

.StripeElement {
  border: 1px solid #E8E8E8;
  padding: 10px 4px;
  border-radius: 4px;
  color: #000;
}

.StripeElement::placeholder {
  color: #d6d6d6;
}

.flag-select__option__icon {
  top: -0.1em !important;
}

.flag-select__btn {
  cursor: pointer;
}

.flag-select__option__label {
  font-weight: 400;
}

[class^="Notify__container"] {
  top: 30px!important;
  overflow: visible!important;
}

[class^="Notification__has-close-all"] {
  overflow: visible!important;
}

[class^="Notification__close-all"] {
  top: -30px!important;
}

/* Move reCAPTCHA v3 badge to the left */
.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
  z-index: 9999;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}

.bold {
  font-weight: bold;
}

.d-flex-column {
  display: flex !important;
  flex-direction: column;
}

.white-space-unset {
  white-space: unset !important;
}

// forbid fixed table's columns for mobile
.rthfc.-sp .rthfc-th-fixed, .rthfc.-sp .rthfc-td-fixed {
  @media screen and (max-width: $mobile-down) {
    position: relative !important;
    left: 0 !important;
  }
}