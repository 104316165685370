[role="button"] {
  cursor: pointer;
}

.btn {
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  border: none;
  border-radius: 4px;
  height: 48px;
  line-height: 48px;
  color: $white;
  font-size: $font-size-button;
  width: 208px;
  text-align: center;

  &:focus {
    outline: none;
  }

  &.btn-small {
    width: 112px;
    height: 40px;
    line-height: 40px;
    font-size: $font-size-main;
  }

  &.btn-datasources {
    width: 178px;
    height: 40px;
    line-height: 40px;
  }

  &.btn-logout {
    height: 40px;
    line-height: 40px;
    width: 132px;
  }

  //Default
  background: $green-gradient;

  &:hover {
    box-shadow: 0 0 10px rgba(61, 177, 74, 0.3);
  }

  // White
  &.color-white {
  color: $green;
  background: $white;
}

  //Green fade
  &.color-green-fade {
    color: #3DB14A;
    background: rgba($green, .1);

    &:hover {
      box-shadow: none;
      background: rgba($green, .15);
    }
  }

  //Red
  &.color-red {
    background: $red;

    &:hover {
      box-shadow: 0 0 10px rgba(246, 125, 125, 0.3);
    }
  }

  //Red fade
  &.color-red-fade {
    color: #F77D7D;
    background: rgba($red, .1);

    &:hover {
      box-shadow: none;
      background: rgba($red, .15);
    }
  }

  // Disabled

  &.disabled {
    opacity: 0.8;
    cursor: not-allowed;
    filter: grayscale(100%);

    &:hover {
      opacity: 0.6;
    }
  }

  &.full-width {
    display: block;
    width: 100%;
  }
}
