@import "variables";

@each $name, $value in $colors {
  .color-#{$name} {
    color: $value;
  }
  .background-#{$name} {
    background: $value;
  }
}
