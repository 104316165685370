@import "~styles/variables";

.sidebarContainer {
  @media screen and (max-width: $hiddenMenu) {
    max-width: 100%;
    width: 100%;
    padding-right: calc(100% - #{$sidebar-width});
    background: rgba(#353333, .5);
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 10000;
    transition: left .2s ease;
  }
}

.sidebarInnerContainer {
  max-width: $sidebar-width;
  min-height: 100vh;
  background: $black;
  position: fixed;
  top: 0;

  @media screen and (max-width: $hiddenMenu) {
    position: static;
  }
}

.sidebarContainerOpened {
  left: 0;
}

.backButtonContainer {
  cursor: pointer;
  background: $green;
}

.logoContainer {
  width: 100%;
  border-bottom: 1px solid rgba($dark-grey, .2);

  @media screen and (max-width: $hiddenMenu) {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  svg {
    max-width: 98px;
    max-height: 40px;
  }
}

.menuContainer {
  width: 100%;
  height: calc(100vh - #{$header-height} - 56px);
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  ul {
    padding-left: 0;
    margin-top: 9px;
  }

  li {
    list-style-type: none;
    flex-flow: column;
    min-width: 240px;
  }

  @media screen and (max-width: $hiddenMenu) {
    order: 2;
  }

  @media screen and (max-width: $mobile-up) {
    height: calc(100vh - #{$header-height} - 162px);
  }
}

label {
  font-weight: 500;
  text-transform: uppercase;
  font-size: $font-size-sm;
  color: $grey;
  opacity: .5;
}

.menuLink {
  padding: 7px 12px;
  font-size: $font-size-main;
  line-height: 1.5;
  color: $light-grey;
  font-weight: 400;
}

.activeLink {
  background: rgba($dark-grey, .2);
  border-right: 4px solid $green;
}

.sidebarButtonsContainer {
  margin-top: auto;

  @media screen and (max-width: $hiddenMenu) {
    order: 1;
    margin-top: 0;
    margin-top: 32px;
  }
}
