@import "~styles/variables";

.button {
	display: block;
	margin-left: auto;
	margin-right: auto;
	border: 0;
	border-radius: 4px;
	background: $green-gradient;
	color: #fff;
	width: 208px;
	height: 48px;
	font-weight: 500;
	font-size: $font-size-button;
	cursor: pointer;
}

.fullWidth {
	width: 100%;
}

.button:disabled {
	background: #ccc;
}

.buttonColorRed {
	background: $red;
}
