.overspendControlReduceWrapper {
  margin-top: 4px;
}

.overspendControlReduceInput {
  padding: 0;
  border: none;
  width: 64px;

  input {
    height: auto;
    font-size: 14px;
    padding: 8px 20px 8px 5px;
    line-height: normal;
  }
}

.overspendControlReduceInputAddon {
  position: absolute;
  top: -3px;
  right: -10px;
  font-size: 14px;
  line-height: 14px;
}

.budgetPacingPeriod {
  max-width: 180px;
}

.border_container {
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    padding: 20px;
    border: 1px solid #3db14a;
    border-radius: 10px;
    pointer-events: none;
  }
}

.checkbox_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 7px;
}
