$primary-color: $green !default;
$ball-size: 20px !default;
$margin: 4px !default;
$line-height: 35px !default;
$line-width: 4px !default;


@mixin global-bg() {
  background-color: $green;
}

@mixin global-animation() {
  animation-fill-mode: both;
}

@mixin balls() {
  @include global-bg();

  width: $ball-size;
  height: $ball-size;
  border-radius: 100%;
  margin: $margin;
}

@mixin lines() {
  @include global-bg();

  width: $line-width;
  height: $line-height;
  border-radius: 2px;
  margin: $margin;
}

@import '../../node_modules/loaders.css/src/animations/ball-pulse-sync';
