/* webkit solution */
.pacing::-webkit-input-placeholder { text-align:right; }
/* mozilla solution */
.pacing:-moz-placeholder { text-align:right; }

.pacing {
  padding: 10px;
  box-shadow: none;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.reduceSpendAddon {
  position: absolute;
  top: 9px;
  right: 10px;
}

.reduceSpendInput {
  padding: 0;
  border: none;
  width: 118px;

  input {
    font-size: 14px;
    padding-right: 26px;
  }
}

.reduceSpendError {
  position: absolute;
  bottom: -20px;
  width: 260px;
  right: 0;
}

.tabs {
  padding: 0;
  width: 130px;
  font-size: 14px;
}
