@import "~styles/variables";

.descriptionMessage {
  max-width: 80%;
}

.slider {
  min-width: 635px;
  padding-left: 20px;
  padding-bottom: 10px;

  @media screen and (max-width: 1280px) {
    min-width: 455px;
    margin-right: 48px;
  }

  @media screen and (max-width: $mobile-down) {
    min-width: 290px;
    margin-right: 0;
  }
}

.planCardContainer {
  @media screen and (max-width: 1280px) {
    flex-flow: column;
    align-items: center;
  }
}

.planCard {
  min-width: 445px;
  max-width: 445px;
  margin-right: 10px;
  margin-left: 10px;

  @media screen and (max-width: $mobile-down) {
    min-width: 360px;
    max-width: 360px;
    margin: 0;
  }

  @media screen and (max-width: 420px) {
    min-width: 335px;
    max-width: 335px;
  }
}

.planCardFirst {
  margin-bottom: 32px;
}

.closeIcon {
  position: absolute;
  top: -35px;
  left: 40px;
}

.customRequestPendingText {
  min-height: calc(100vh - 120px);
}
