@import "~styles/variables";

.addDataSourceWrapper {
  border: 1px solid $black;
}

.addDataSourceWrapperLeft {
  @media screen and (max-width: $mobile-down) {
    margin-right: 0;
    margin-bottom: 24px;
  }
}
