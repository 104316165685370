@import "~styles/variables";

.submitButton {
  min-width: 120px;
  height: 40px;

  margin-left: 24px;

  @media screen and (max-width: $mobile-up) {
    margin: 0;
  }
}

.tableContainer {
  max-height: 216px;
  overflow-y: auto;
}

.textOpacity {
  opacity: .3;
}
