@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow-regular-webfont.woff2') format('woff2'),
  url('../assets/fonts/barlow-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow-medium-webfont.woff2') format('woff2'),
  url('../assets/fonts/barlow-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow-semibold-webfont.woff2') format('woff2'),
  url('../assets/fonts/barlow-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

