.wrapper {
  border-radius: 4px;
}

.container {
  width: 150px;
  position: absolute;
  left: 50%;
  top: 15px;
  transform: translateX(-50%);
}
  