@import "~styles/variables";

.headerContainer {
  box-shadow: 0 0 50px rgba(53, 51, 51, 0.05);

  @media screen and (max-width: $hiddenMenu) {
    background: $white;
    width: 100%;
    padding: 0 24px 0 0;
    position: sticky;
    top: 0;
    z-index: 999;

    .menuIconContainer {
      cursor: pointer;
      background: $green;
    }
  }
}

.userName {
  margin: 0 12px;
}

.dropdownContainer {
  position: absolute;
  right: 0;
  background: #fff;
  top: 40px;
  border-radius: 4px;
  box-shadow: 0 0 50px rgba(53, 51, 51, 0.05);
  z-index: 1000;
}
