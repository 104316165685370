.recharts-responsive-container {
  position: relative;
  margin-top: 25px;
  box-shadow: 0 0 50px rgba(53, 51, 51, 0.05);
  border: none;
  border-radius: 8px;

  box-sizing: content-box;
  padding: 40px 0;
}
