@import "~styles/variables";

.addUserButton {
  margin: 0;

  @media screen and (max-width: $mobile-up) {
    width: 150px;
    margin: 8px 0 0;
  }
}
