@import "~styles/variables";

.settingsContainer {
  @media screen and (max-width: $tablet-down) {
    flex-flow: column;
  }
}

.settingsFormsContainer {
  width: 33%;

  @media screen and (max-width: $tablet-down) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-direction: row;
    margin-top: 32px;
  }

  @media screen and (max-width: $mobile-down) {
    flex-flow: column;
  }
}
