@import "variables";

html {
  font-size: $font-size-main;
}

body {
  font-family: 'Barlow', sans-serif;
}

p {
  margin: 0;
}

.fw-regular {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.text-small {
  font-size: 12px;
}

// Sizes
.fs-sm {
  font-size: $font-size-sm;
  line-height: 1;
}

.fs-main {
  font-size: $font-size-main;
  line-height: $font-size-main;
}

.fs-button {
  font-size: $font-size-button;
}

.fs-lg {
  font-size: $font-size-lg;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fs-40 {
  font-size: 40px;
}

.fs-sm-main {
  @media screen and (max-width: $mobile-down) {
    font-size: 20px;
  }
}
