@import "~styles/variables";

.avatar {
	border-radius: 50%;
	height: 120px;
	width: 120px;
	object-fit: cover;
}

.uploadButton {
	text-transform: none;
	font-weight: 600;
	color: $green;
	border-radius: 4px;
	opacity: 1;
	background: rgba($green, .1)
}

.removePhotoButton {
	background: transparent;
	color: $red;

	&:hover {
		box-shadow: none;
	}
}
