@import "~styles/variables";

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  right: auto;
  bottom: auto;
  background: white;
  border-radius: 16px;
  transform: translate(-50%, -50%);
  outline: none;
  max-height: 90%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  &.modalSm {
    width: 350px;
  }

  &.modalMd {
    width: 540px;
    @media screen and (max-width: $mobile-down) {
      width: calc(100% - 30px);
      padding: 24px;
    }
  }

  &.modalLg {
    width: 780px;
    @media screen and (max-width: $mobile-up) {
      width: calc(100% - 30px);
      padding: 24px;
    }
  }

  &.modalXl {
    width: 960px;
    @media screen and (max-width: $mobile-up) {
      width: calc(100% - 30px);
      padding: 24px;
    }
  }
}

.showScrollbar {
  overflow: hidden;
}

.overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#353333, 0.5);
}

.modalStretchHeight {
  max-height: 90%;
}
