.buttonBox {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin: 0;
  }
}

.scroll {
  overflow-y: scroll !important;
  scrollbar-width: thin !important;
  height: 70vh;
  padding: 0 25px;
  margin: 0 -35px;
}
