@import "~styles/variables";

.input:not(.rc-input-number), .rc-input-number input {
  font-size: $font-size-main;
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: 1px solid $grey;
  border-radius: 4px;
  padding: 8px 12px;

  &::placeholder {
    opacity: .3;
  }

  &:disabled {
    background: $grey;
    opacity: .5;
  }

  &.input-small {
    height: 32px;
  }
}

.input-optional {
  opacity: .3;
  line-height: $font-size-sm;
}

.input-invalid {
  border-color: $red;
}

.input-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 13px;
  bottom: 12px;
  z-index: 100;
}

.react-datepicker-wrapper {
  width: 100%;
}

.rt-th .react-datepicker-popper {
  transform: translate3d(0px, 32px, 0px)!important;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;
}

.react-tel-input .flag-dropdown {
  border-color: #e8e8e8 !important;
}

.react-tel-input .form-control {
  padding-left: 48px;
}

.multi-select {
  --rmsc-h: 30px!important;
  --rmsc-p: 4px!important;
  --rmsc-gray: $black!important;

  .dropdown-container {
    border: 1px solid $grey;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='4' viewBox='0 0 7 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 4L0 0H7L3.5 4Z' fill='%23353333'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: bottom 50% right 8px;
  }

  .dropdown-heading {
    text-align: left;
  }

  .dropdown-heading-value span {
    color: $black;
  }

  .dropdown-heading-dropdown-arrow {
    display: none;
  }

  .select-panel {
    .item-renderer {
      text-align: left;
      span {
        text-transform: capitalize;
        font-weight: 500;
        font-size: $font-size-sm;
        color: $black;
      }
    }
    label {

    }

    input {
      margin: 0;
      padding: 0;
    }
  }

  input {
    font-weight: 500;
    font-size: $font-size-sm;
  }
}

// hide arrows of input type number
input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.rc-input-number {
  &.input-small {
    max-width: 64px;

    input {
      padding: 8px;
      height: 32px;
    }
  }

  &.input-small-height {
    max-height: 32px;
    max-width: 100px;

    input {
      padding: 8px;
      height: 32px;
    }
  }
}

.flag-select-input {
  .flag-select__btn {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    border: 1px solid $grey;
    padding: 8px 12px;
  }

  .flag-select__option--placeholder {
    margin: 0;
    padding: 0;
    display: flex;
    align-self: center;

    img {
      align-self: center;
    }

    .flag-select__option__label {
      font-weight: 500;
      color: $black;
      font-size: $font-size-main;
      line-height: 1.5;
    }
  }

  .flag-select__options {
    width: 100%;

    .filterBox {
      margin-bottom: 8px;
    }

    .flag-select__option {
      margin: 0;
      padding: 8px 12px;
    }
  }
}

.input-addon {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 15px;
  top: 12px;
}
