@import "~styles/variables";

.Toastify__toast {
  z-index: 10000;
  padding: 16px;

  &--success {
    background: #ecf7ed;
  }

  &--error {
    background: #fef2f2;
  }

  &-body {
    font-size: $font-size-main;
    font-weight: 500;
    color: $black;
  }
}

.Toastify__close-button {
  color: $black;
}
