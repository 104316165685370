@import "~styles/variables";

.buttonStatus {
  max-width: 132px;
}

.dotStatus {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: $red;
}

.dotStatusActive {
  background-color: $green;
}

.buttonEdit {
  max-width: 88px;
}

.arrowLeft {
  margin-right: 27px;
}

.disabled {
  background: #ccc;
}
