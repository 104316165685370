@import "~styles/variables";

.radioButtonInput {
  position: relative;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  background: $light-grey;
  cursor: pointer;
  border: 1px solid $grey;
  border-radius: 50%;
  margin: 0;

  &::before {
    content: ' ';
    background: $green-gradient;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    opacity: 0;
    transition: opacity .2s;
  }

  &:checked {
    &::before {
      opacity: 1;
    }
  }
}
