@import "~styles/variables";

.container {
  border-radius: 10px;
  min-height: 96px;
  font-size: 18px;
  max-width: 520px;
  padding: 8px 0;
  margin: 0 auto 16px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  &WithoutCoupon {
    border: 1px dashed $green;
    color: $green;
    cursor: pointer;
    justify-content: center;

    &:hover {
      opacity: 0.8;
    }
  }

  &WithCoupon {
    background-color: $green;
    position: relative;
    color: white;
    font-size: 1.3rem;

    .circle {
      background-color: white;
      width: 55px;
      height: 55px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &Left {
        left: -35px;
      }

      &Right {
        right: -35px;
      }
    }
  }
}

.openCouponInfo {
  @media screen and (min-width: $mobile-down) {
    margin-left: 30px;
  }
}

.button {
  background: white;
  color: $green;
  margin-right: 30px;
  margin-left: 30px;

  @media screen and (max-width: $mobile-down) {
    margin-right: auto;
    margin-left: auto;
  }

  &:hover {
    opacity: 0.9;
  }
}

.mobile {
  @media screen and (max-width: $mobile-down) {
    flex-direction: column;
    align-items: stretch;
  }
}

.cancelButton {
  background: transparent;
  color: $green;
}

.modal {
  padding: 16px !important;
  width: 100%;
  max-width: 350px;
}

.inputWrapperInsideModal {
  width: 254px;
  margin: 0 auto;
}

.rmvButton:hover {
  text-decoration: underline;
}
