@import "~styles/variables";

.invoicesContainer {
  margin: 0 25px;

  @media screen and (max-width: $tablet-down) {
    flex-flow: column;
  }
}

.tableContainer {
  width: 65%;
  margin-top: 8px;

  @media screen and (max-width: $tablet-down) {
    width: 100%;
    margin-bottom: 40px;
  }
}

.paymentMethodContainer {
  width: 30%;

  @media screen and (max-width: $tablet-down) {
    width: 100%;
    max-width: 344px;
  }
}

.paymentMethodHeader {
  margin-bottom: 20px;
}

.downloadIcon {
  display: block;
  width: 16px;
  height: 2px;
  background: $green;
  margin-top: 2px;
}

.plusButton {
  border: none;
  border-radius: 8px;
  background: rgba($green, .1);
  width: 40px;
  height: 40px;

  &:hover {
    background: rgba($green, .2);
  }
}

