@import "~styles/variables";

.card {
	position: relative;
	width: 100%;
	height: 232px;
	background: #FFFFFF;
	box-shadow: 0 0 50px rgba(53, 51, 51, 0.05);
	border-radius: 8px;

	@media screen and (max-width: $mobile-down) {
		height: auto;
	}
}

.title {
	display: block;
	font-weight: 600;
	font-size: 28px;
	line-height: 34px;
	text-align: center;
	color: #353333;
}

.subtitle {
	display: block;
	font-weight: 600;
	font-size: 40px;
	line-height: 48px;
	text-align: center;
	color: #3DB14A;
}

.cardFilled {
	position: relative;
	width: 100%;
	height: 232px;
	background: #3DB14A;
	box-shadow: 0 0 50px rgba(53, 51, 51, 0.05);
	border-radius: 8px;

	@media screen and (max-width: $mobile-down) {
		height: auto;
	}
}

.titleFilled {
	font-weight: 600;
	font-size: 28px;
	line-height: 34px;
	display: flex;
	justify-content: space-between;
	color: #fff;
}

.subtitleFilled {
	display: block;
	font-weight: 600;
	font-size: 40px;
	text-align: center;
	color: #fff;
}

.discount {
	position: absolute;
	top: 16px;
	right: 16px;

	color: #3DB14A;
	background: #fff;
	padding: 5px 10px;
	border-radius: 25px;

	font-weight: 600;
	font-size: 16px;
	line-height: normal;
}

.leftSideSubTitle {
	list-style-type: none;
	padding: 0;
	opacity: .7;

	@media screen and (max-width: $mobile-down) {
		margin: 8px 0 8px;
	}
}

.leftSideSubTitle li {
	color: #fff;
	text-align: left;
	font-weight: 400;
}

.filledPrice {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media screen and (max-width: $mobile-down) {
		padding: 0;
		flex-flow: column;
	}
}

.price {
	display: flex;
	justify-content: center;
}

.month {
	font-weight: 400;
	font-size: 19px;
	line-height: 19px;
	text-align: center;
	margin-left: 5px;
	color: grey;
}

.monthFilled {
	font-weight: 400;
	font-size: 19px;
	line-height: 19px;
	text-align: center;
	margin-left: 5px;
	color: #fff;
}

.priceWrapper {
	display: flex;
	align-items: baseline;
	flex-wrap: wrap;
	justify-content: flex-end;

	@media screen and (max-width: $mobile-down) {
		margin-bottom: 16px;
	}
}

.selectCurrencyLable {
	color: grey;
	font-weight: 400;
}

.submitButton {
	background-color: #ecf7ed;
}
