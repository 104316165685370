@import "~styles/variables";

.refreshButton {
  background-color: rgba(245, 176, 0, 0.8);
  border: none;
  border-radius: 16px;
  color: white;
  padding: 12px 75px;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  transition: background-color .2s linear;
  margin: 16px 0;

  @media screen and (max-width: $mobile-down) {
    width: 100%;
  }

  &:hover {
    background-color: rgba(245, 176, 0, 1);
  }
}


.pop {
  animation: animate-pop 0.5s cubic-bezier(.16, 1.53, .68, .99);
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.8, 0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
