@import "~styles/variables";

.thumbWrapper {
    background: linear-gradient(86.49deg, #73C94B 5.78%, #15B568 94.22%);
    border-radius: 8px;
    margin: 10px 0;
    padding: 24px 12px;
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: $mobile-up) {
        flex-flow: column;
        align-items: center;
    }
}

.thumbItem {
    width: 32%;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    @media screen and (max-width: $mobile-up) {
        width: 100%;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 5px;
    }

    span {
        margin-left: 10px;
        color: $dark-grey;
    }

    img {
        vertical-align: middle;
    }
}

.thumbImg {
    padding: 20px;
}

.naxtImg {
    padding-right: 25px;
}

.thumbContent {
    display: flex;
    align-items: center;
}
