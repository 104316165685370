@import '~styles/variables';
@import '~styles/functions';

// Text alignment

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Images

.object-fit-cover {
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

// Flex

.d-flex {
  display: flex;
}

.d-flex-lg {
  @media screen and (min-width: $mobile-down) {
    display: flex;
  }
}

.justify-content-start {
  justify-content: start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-center-sm {
  @media screen and (max-width: $mobile-down) {
    align-items: center;
  }
}

.align-items-center-sm-up {
  @media screen and (max-width: $mobile-up) {
    align-items: center;
  }
}

.align-items-end {
  align-items: flex-end;
}

.align-self-start {
  align-self: start;
}

.align-self-start-sm {
  @media screen and (max-width: $mobile-down) {
    align-self: flex-start;
  }
}

.align-self-center {
  align-self: center;
}

.align-self-end-sm {
  @media screen and (max-width: $mobile-down) {
    align-self: flex-end;
  }
}

.flex-column {
  flex-flow: column;
}

.flex-column-md-down {
  @media screen and (max-width: $tablet-down) {
    flex-flow: column;
  }
}

.flex-column-sm-up {
  @media screen and (max-width: $mobile-up) {
    flex-flow: column;
  }
}

.flex-column-sm {
  @media screen and (max-width: $mobile-down) {
    flex-flow: column;
  }
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-sm {
  @media screen and (max-width: $mobile-down) {
    flex-wrap: wrap;
  }
}

.flex-grow-sm {
  @media screen and (max-width: $mobile-down) {
    flex-grow: 1;
  }
}

.d-none {
  display: none;
}

.d-none-sm {
  @media screen and (max-width: $mobile-down) {
    display: none;
  }
}

.d-inline-block {
  display: inline-block;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.full-width {
  width: 100vw;
}

.full-height {
  height: 100vh;
}

.transparent-btn {
  background: #fff;
  border: 0;
  color: #3DB14A;
}

.absolute-center {
  position: absolute;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.card {
  background: $white;
  box-shadow: 0 0 50px rgba(53, 51, 51, 0.05);
  border-radius: 8px;
  padding: 25px;

  @media screen and (max-width: $tablet-down) {
    width: calc(50% - 12px)
  }
}

.block-width {
  width: 100%;
}

// height & width

@for $i from 0 through 20 {
  .w-#{$i} {
    width: dimension($i);
  }

  .w-smup-#{$i} {
    @media screen and (max-width: $mobile-up) {
      width: dimension($i);
    }
  }

  .w-sm-#{$i} {
    @media screen and (max-width: $mobile-down) {
      width: dimension($i);
    }
  }

  .h-#{$i} {
    height: dimension($i);
  }
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0;
}

.divider {
  min-width: 24px;

  @media screen and (max-width: $mobile-down) {
    display: none;
  }
}

.w-100 {
  width: 100%;
}

.mw-920 {
  max-width: 920px;
}

.mw-1110 {
 max-width: 1110px;
}

.z-index-1 {
  z-index: 1;
}

.z-index-9 {
  z-index: 9;
}

.hover-text-decoration-disable {
  &:hover {
    text-decoration: none;
  }
}

@keyframes colorChange {
  0% {
    background-color: rgba(255, 0, 0, 0.5);
  }

  100% {
    background-color: $white;
  }
}

.colorChangingTab {
  animation: colorChange 2s 1;
}

.overflow-hidden {
  overflow: hidden;
}

.crossedOutTextGrey {
  position: relative;
  color: #9B9B9B;
  display: inline-block;
  margin-right: 8px;

  &:before {
    content: '';
    position: absolute;
    left: -3px;
    right: -5px;
    height: 50%;
    border-bottom: 4px solid #9B9B9B;
    transform: rotate(345deg);
    transform-origin: center bottom 0;
  }
}

.crossedOutTextWhite {
  position: relative;
  color: white;
  display: inline-block;
  margin-right: 8px;

  &:before {
    content: '';
    position: absolute;
    left: -3px;
    right: -5px;
    height: 50%;
    border-bottom: 4px solid white;
    transform: rotate(345deg);
    transform-origin: center bottom 0;
  }
}

.crossedOutTextSmall {
  position: relative;
  color: white;
  display: inline-block;
  margin-right: 4px;

  &:before {
    content: '';
    position: absolute;
    left: -3px;
    right: -5px;
    height: 50%;
    border-bottom: 2px solid white;
    transform: rotate(345deg);
    transform-origin: center bottom 0;
  }
}
