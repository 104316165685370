@import "~styles/variables";

.thumbnailWrapper {
  border-radius: 8px;
  box-shadow: 0 0 50px rgba(53, 51, 51, 0.05);

  @media screen and (max-width: 900px) {
    flex-flow: column;
  }
}

.thumbnailItemsWrapper {
  justify-content: center;


  @media screen and (max-width: 1520px) {
    justify-content: space-between;
    margin-right: 16px;
    margin-left: 16px;
  }

  @media screen and (max-width: $tablet-down) {
    flex-flow: column;
    padding: 20px 20px;
    align-self: stretch;
  }

  @media screen and (max-width: 900px) {
    max-width: 350px;
    align-self: center;

    p:first-child {
      min-width: 220px;
      text-align: left;
    }

    p:last-child {
      text-align: right;
      font-size: 16px;
    }
  }
}

.thumbnailPriceText {
  @media screen and (max-width: 1520px) {
    font-size: $font-size-lg;
  }
}

.thumbnailItem {
  width: 100%;
  margin: 0 32px;

  @media screen and (max-width: 1520px) {
    margin: 0;
  }

  @media screen and (max-width: $tablet-down) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;

      &:nth-child(2) {
        margin-right: 8px;
      }
    }
  }
}

.thumbnailItemFirst {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  min-width: 280px;
  padding: 30px;
  background: linear-gradient(86.49deg, #73C94B 5.78%, #15B568 94.22%);
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;

  @media screen and (max-width: $laptop) {
    min-width: 200px;
    padding: 30px 0;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

.thumbnailLastItem {
  padding: 30px 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  @media screen and (max-width: 900px) {
    padding: 0 0 16px;
  }

  @media screen and (max-width: $mobile-up) {
    margin-right: 0;
  }
}

.currentBillingPlanPeriod {
  line-height: 1.25;
}
