@import "~styles/variables";

.personalInfoCard {
  width: 65%;
  height: 100%;

  @media screen and (max-width: $tablet-down) {
    width: 100%;
  }
}

.submitButton {
  height: 40px;
  margin-top: 5px;
}
