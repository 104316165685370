.form-control.StripeElement {
  padding-top: 9px;
  &.StripeElement--focus {
    color: #495057;
    background-color: #fff;
    border-color: #bcbff0;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &.StripeElement--invalid {
    border-color: #dc3545;
  }

  &.StripeElement--invalid ~ .invalid-feedback {
    display: block;
  }

  &.StripeElement--focus.StripeElement--invalid {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
}
