@import "~styles/variables";

.creditCard {
  position: relative;
  border-top: 10px solid $green;
  border-radius: 10px;
  background-color: $white;
  box-shadow: 0 0 50px rgba(53, 51, 51, 0.05);
}

.leftSide {
  width: 350px;
}

.rightSide {
  width: 350px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-self: center;

  @media screen and (max-width: $mobile-up) {
    margin-left: 0;
    margin-top: 40px;
  }
}

.closeIconWrapper {
  position: absolute;
  left: 16%;
  top: 50px;
}

.caption {
  max-width: 540px;

  @media screen and (max-width: $mobile-up) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
