@import "~styles/variables";

.tooltipContainer {
  position: absolute;
  background: $white;
  opacity: 1;
  padding: 10px;
  border: 1px solid $grey;
  z-index: 10;
}

.graphConverted {
  transform: translateX(-100%);
}
