@import "~styles/variables";

.checkboxLabel {
  font-size: $font-size-main;
  color: $black;
  margin-left: 12px;
  text-transform: none;
  opacity: 1;
}

.checkboxLabelDisabled {
  color: $dark-grey;
}

.checkboxInput {
  position: relative;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  background: $light-grey;
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin: 0 12px 0;

  &::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.6288 0.763457C14.2617 0.411187 13.6946 0.414645 13.3371 0.759049L5.61027 8.17642L2.66846 5.35273C2.31352 5.01085 1.7335 5.01013 1.37415 5.35505L0.782731 5.92353C0.401126 6.28871 0.411087 6.87062 0.780663 7.22293L0.781819 7.22403L4.96472 11.239C5.09511 11.3641 5.25405 11.4418 5.41759 11.4755L5.45498 11.5025L5.62045 11.4999C5.84832 11.4965 6.07438 11.4097 6.24957 11.245L15.2257 2.62766C15.5965 2.27182 15.588 1.6842 15.2207 1.33159L14.6288 0.763457Z' fill='%233DB14A'/%3E%3C/svg%3E%0A");
    position: absolute;
    top: 5.5px;
    right: 3.5px;
    bottom: 5.5px;
    left: 3.5px;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:checked {
    &::before {
      opacity: 1;
    }
  }
}

.checkboxSwitch {
  width: 64px;
  height: 32px;
  border: 1px solid $grey;
  border-radius: 100px;

  &::before {
    content: " ";
    width: 24px;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    opacity: 1;
    border-radius: 50%;
    background: $grey;
    transition: left 0.2s;
  }

  &:checked {
    &::before {
      left: 36px;
      background: $green-gradient;
    }
  }
}

.disabled_checkbox {
  background-color: rgba(128, 128, 128, 0.516);
  cursor: not-allowed;
}
