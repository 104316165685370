@import "~styles/variables";

.badge {
  display: inline-block;
  padding: 4px 8px;
  background: rgba(61, 177, 74, 0.1);
  color: $black;
  font-size: $font-size-sm;
  font-weight: 500;
  border-radius: 4px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
