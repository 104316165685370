@import "../../styles/variables";

.modalUpdateContainer {
  max-height: 300px;
  flex: 1;
  overflow: auto;
}

.itemsContainer {
  //max-height: 250px;
  overflow-y: auto;
  margin-bottom: 32px;
}

.actionsWrapper {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
}

.status {
  margin-right: 10px;
  width: 100%;
}

.title {
  margin-bottom: 8px;
  line-height: 16px;
}

.statusSelect, .budgetInput {
  width: 100%;
  max-width: 100%;
  font-size: $font-size-sm;
  font-weight: 500;
  height: 32px;
  padding: 7px;
  line-height: 16px;
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,0.1);
}

.statusSelect {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='4' viewBox='0 0 7 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 4L0 0H7L3.5 4Z' fill='%23353333'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: bottom 50% right 8px;
}

.dailyBudget {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
}

.whiteSpace {
  white-space: normal;
}

.stableHeight {
  &::before {
    content: '\00a0';
  }
}

.bulkUpdateModalSize {
  width: 350px;
  padding: 24px;

  @media screen and (min-width: 640px) {
    width: 400px;
  }

  @media screen and (min-width: 768px) {
    width: 540px;
  }

  @media screen and (min-width: 1024px) {
    width: 780px;
  }

  @media screen and (min-width: 1280px) {
    width: 960px;
  }
}
