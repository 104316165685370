.formWrapper {
  width: 100%;
  form {
    width: 100%;
  }
}

.formInner {
  padding-bottom: 300px;
}

.formInnerMaxWidth {
  max-width: 444px;
  margin-right: auto;
  margin-left: auto;
}

.buttonsBarWrapper {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 50px rgba(53, 51, 51, .05);
  background: #fff;

  @media (max-width: 576px) {
    z-index: 999;
  }
}

.progressBarSm {
  margin: 0 12px;
}
