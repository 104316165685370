.formWrapper {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 767px) {
    margin-top: 48px;
    display: block;
    width: auto;
    height: auto;
    padding: 8px;
  }
}

.email {
  margin-top: 80px;

  @media (max-width: 767px) {
    margin-top: 48px;
  }
}

.passwordHint {
  font-size: 14px;
  color: #333;
}
