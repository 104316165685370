@import "~styles/variables";

.linksContainer {
  border: 1px solid $grey;
  border-radius: 4px;

  @media screen and (max-width: $mobile-down) {
    margin-right: 0;

    &:first-child {
      margin-bottom: 16px;
    }
  }
}

.menuLink {
  border-radius: 4px;
  min-width: 88px;
  padding: 7px 12px;
  font-size: $font-size-main;
  line-height: 1.5;
  color: $green;
  background-color: $white;
  transition: .2s ease;

  &:hover {
    text-decoration: none;
  }
}

.activeLink {
  background: $green-gradient;
  color: $white;
}
